define("ember-svg-jar/inlined/meta-colored", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.68 11.48c0 .594.13 1.05.3 1.326.224.362.558.515.898.515.438 0 .839-.109 1.612-1.177.619-.857 1.348-2.06 1.84-2.813l.83-1.278c.578-.887 1.246-1.873 2.012-2.541.626-.545 1.301-.849 1.98-.849 1.14 0 2.227.661 3.058 1.9.91 1.358 1.351 3.067 1.351 4.832 0 1.048-.207 1.82-.558 2.428C16.663 14.412 16 15 14.886 15V13.32c.954 0 1.192-.877 1.192-1.88 0-1.43-.333-3.016-1.067-4.15-.522-.804-1.197-1.296-1.94-1.296-.804 0-1.45.606-2.177 1.687-.387.574-.783 1.274-1.229 2.064l-.49.868c-.985 1.747-1.234 2.144-1.727 2.8-.863 1.15-1.6 1.586-2.57 1.586-1.152 0-1.88-.498-2.33-1.25-.368-.612-.549-1.415-.549-2.33l1.68.06z\" fill=\"#0081FB\"/><path d=\"M3.325 6.682c.77-1.188 1.882-2.019 3.158-2.019.739 0 1.473.22 2.24.845.839.685 1.733 1.812 2.848 3.67l.4.667c.965 1.608 1.515 2.435 1.836 2.826.414.5.703.65 1.08.65.953 0 1.191-.877 1.191-1.88l1.483-.046c0 1.048-.207 1.82-.558 2.428C16.663 14.412 16 15 14.886 15c-.693 0-1.306-.15-1.985-.79-.521-.492-1.131-1.365-1.6-2.15L9.904 9.73c-.7-1.17-1.343-2.043-1.715-2.438-.4-.424-.914-.937-1.734-.937-.664 0-1.228.465-1.7 1.178l-1.431-.85z\" fill=\"url(#paint0_linear_1304_12434)\"/><path d=\"M6.456 6.354c-.664 0-1.228.465-1.7 1.178C4.089 8.54 3.68 10.04 3.68 11.48c0 .594.13 1.05.3 1.326l-1.432.944c-.368-.612-.549-1.415-.549-2.33 0-1.664.457-3.399 1.326-4.738.77-1.188 1.882-2.019 3.158-2.019l-.027 1.69z\" fill=\"url(#paint1_linear_1304_12434)\"/><defs><linearGradient id=\"paint0_linear_1304_12434\" x1=\"4.159\" y1=\"10.995\" x2=\"15.866\" y2=\"11.642\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#0064E1\"/><stop offset=\".4\" stop-color=\"#0064E1\"/><stop offset=\".83\" stop-color=\"#0073EE\"/><stop offset=\"1\" stop-color=\"#0082FB\"/></linearGradient><linearGradient id=\"paint1_linear_1304_12434\" x1=\"4.434\" y1=\"12.186\" x2=\"4.434\" y2=\"8.235\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#0082FB\"/><stop offset=\"1\" stop-color=\"#0064E0\"/></linearGradient></defs>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});