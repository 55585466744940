define("ember-svg-jar/inlined/tiktok-colored", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.893 8.093A5.285 5.285 0 0016 9.098V6.845c-.22 0-.438-.023-.652-.07V8.55a5.286 5.286 0 01-3.107-1.005v4.598c0 2.3-1.845 4.165-4.12 4.165a4.07 4.07 0 01-2.294-.704 4.086 4.086 0 002.946 1.253c2.276 0 4.12-1.865 4.12-4.165V8.093zm.805-2.273a3.156 3.156 0 01-.805-1.839v-.29h-.618a3.156 3.156 0 001.423 2.129zm-6.432 8.019a1.91 1.91 0 01-.385-1.154c0-1.052.844-1.905 1.885-1.905.194 0 .387.03.572.09V8.565a4.112 4.112 0 00-.652-.038v1.793a1.868 1.868 0 00-.572-.09 1.895 1.895 0 00-1.885 1.906c0 .744.422 1.388 1.037 1.702z\" fill=\"#FF004F\"/><path d=\"M12.24 7.545a5.286 5.286 0 003.108 1.005V6.776a3.11 3.11 0 01-1.65-.956 3.156 3.156 0 01-1.423-2.129h-1.624v9a1.895 1.895 0 01-1.885 1.9 1.875 1.875 0 01-1.5-.752 1.907 1.907 0 01-1.037-1.702c0-1.053.844-1.905 1.885-1.905.2 0 .392.03.572.089V8.528c-2.236.046-4.034 1.893-4.034 4.164 0 1.133.448 2.161 1.175 2.912a4.07 4.07 0 002.293.704c2.276 0 4.12-1.864 4.12-4.165V7.545z\" fill=\"#000\"/><path d=\"M15.348 6.776v-.48a3.082 3.082 0 01-1.65-.476 3.108 3.108 0 001.65.956zM12.275 3.69a3.264 3.264 0 01-.034-.258v-.29H10v9a1.895 1.895 0 01-1.885 1.899 1.86 1.86 0 01-.848-.203c.344.457.888.752 1.5.752 1.04 0 1.882-.85 1.885-1.9v-9h1.624zM8.686 8.528v-.511a4.118 4.118 0 00-.565-.039C5.845 7.978 4 9.843 4 12.143c0 1.442.725 2.713 1.827 3.461a4.174 4.174 0 01-1.175-2.912c0-2.271 1.798-4.118 4.034-4.164z\" fill=\"#00F2EA\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});