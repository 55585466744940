define("ember-svg-jar/inlined/google-ad-colored", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.322 13.112l4.654-7.968c.592.348 3.573 1.998 4.054 2.312l-4.654 7.968c-.509.672-4.698-1.292-4.054-2.313v.001z\" fill=\"#FBBC04\"/><path d=\"M16.694 13.112L12.04 5.145a2.373 2.373 0 00-3.203-.837c-1.15.64-1.501 2.017-.85 3.148l4.653 7.968a2.374 2.374 0 003.203.837c1.101-.64 1.502-2.066.851-3.148v-.001z\" fill=\"#4285F4\"/><path d=\"M5.336 16.569c1.29 0 2.336-1.02 2.336-2.279 0-1.258-1.046-2.278-2.336-2.278-1.29 0-2.336 1.02-2.336 2.278 0 1.259 1.046 2.279 2.336 2.279z\" fill=\"#34A853\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});